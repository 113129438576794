
export const post = [
    {
      "id": 1,
      "dataCreated": "10/09/2024",
      "Title": "Welcome to my blog",
      "Description": "This is my new blog, wlecome back my friend"
    },
    {
      "id": 2,
      "dataCreated": "11/09/2024",
      "Title": "Next blog",
      "Description": "This is my second blog, wlecome back my friend"
    },    {
      "id": 3,
      "dataCreated": "11/09/2024",
      "Title": "Third blog",
      "Description": "This is my third blog, wlecome back my friend"
    }
  ]
